import React from "react"
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = ({ location }) => (
  <Layout location={{ location }}>
    <SEO titleText="Four-o-Four" />
    <div className="py-10 px-2 md:px-1 text-center min-h-96">
          <div className="max-w-7xl w-full inline-block text-left px-2 lg:px-3">
              <h1 className="text-3xl font-bold my-3">PAGE NOT FOUND</h1>
              <p className="py-1 pb-4 text-base">Sorry, the page you are trying to visit does not exist or have been removed.</p>
              <div className="text-sm mt-5 text-gray-600">
                <span className="block my-2 lg:my-1">← <a href="/" className="hover:underline font-bold text-black">Maker Stories Homepage</a></span>
              </div>
          </div>
      </div>
  </Layout>
)

export default NotFoundPage